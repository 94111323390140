import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Analytics } from '@envato/google-analytics'
import { Link, Meta, Title } from 'react-head'
import { snakeCase } from 'snake-case'
import ScrollToTop from '../components/scroll_to_top/scroll_to_top'
import logo72 from '../shortcut_icons/logo_72.png'
import logo144 from '../shortcut_icons/logo_144.png'
import PageLayoutWithHeader from '../components/page_layout_with_header/page_layout_with_header'
import Hyperlink from '../components/hyperlink/hyperlink'
import I18nContext from '../contexts/i18n_context'
import { languages } from '../i18n/languages'
import usePageId from '../routing/use_page_id'
import { UIProvider } from '@envato/ui'

Page.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  lang: PropTypes.oneOf(Object.values(languages)),
  alternate: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired
}

export default function Page({ title, description, path, children, lang, alternate }) {
  const pageId = usePageId()

  useEffect(() => document.documentElement.setAttribute('lang', lang), [lang])

  return (
    <UIProvider value={{ LinkComponent: Hyperlink }}>
      <I18nContext.Provider value={{ language: lang }}>
        {/* I18n */}
        {alternate.map(([altLang, i18nPath]) => (
          <Link key={altLang} rel='alternate' hreflang={altLang} href={`https://www.envato.com${i18nPath}`} />
        ))}

        <Title>{title}</Title>
        <Meta name='description' content={description} />

        {/* SEO */}
        <Link rel='canonical' href={`https://www.envato.com${path}`} />
        <Meta name='google-site-verification' content='jM82iu9ggqmee94Ce9o23yaagwxAB5pMYcQALy2LL0M' />
        {/* Note Feb 2020: SEO team aren't sure if anyone still has access to this GSC account. They're investigating. */}
        <Meta name='google-site-verification' content='HiOAJhVpg977EldJw73tBea03vaXJrEfOhMJPb9nHKo' />

        {/* Shortcut icons */}
        <Link rel='shortcut icon' href={logo72} />
        <Link rel='apple-touch-icon' href={logo144} />

        {/* Social sharing & Open Graph */}
        <Meta property='og:locale' content='en_US' />
        <Meta property='og:type' content='website' />
        <Meta property='og:title' content={title} />
        <Meta property='og:description' content={description} />
        <Meta property='og:url' content={`https://www.envato.com${path}`} />
        <Meta property='og:site_name' content='Envato' />

        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              'name': 'Envato Pty Ltd.',
              'alternateName': 'Envato',
              'url': 'https://www.envato.com/about/',
              'logo': 'https://elements.envato.com/favicon.ico',
              'contactPoint': {
                '@type': 'ContactPoint',
                'telephone': '+61 3 8376 6284',
                'contactType': 'corporate contact'
              },
              'sameAs': [
                'https://www.facebook.com/envato',
                'https://twitter.com/envato',
                'https://www.instagram.com/envato/',
                'https://www.youtube.com/c/envato',
                'https://www.linkedin.com/company/envato/',
                'https://www.pinterest.nz/envato/',
                'https://elements.envato.com',
                'https://tutsplus.com',
                'https://themeforest.net',
                'https://codecanyon.net',
                'https://audiojungle.net',
                'https://videohive.net',
                'https://audiojungle.net',
                'https://graphicriver.net',
                'https://photodune.net',
                'https://3docean.net',
                'https://placeit.net',
                'https://mixkit.co/',
                'https://www.reshot.com/'
              ],
              'subOrganization': [
                {
                  '@type': 'Organization',
                  'name': 'Envato Elements',
                  'url': 'https://elements.envato.com'
                },
                {
                  '@type': 'Organization',
                  'name': 'Envato Market',
                  'url': 'https://market.envato.com'
                },
                {
                  '@type': 'Organization',
                  'name': 'Placeit',
                  'url': 'https://placeit.net/'
                },
                {
                  '@type': 'Organization',
                  'name': 'Envato Tuts+',
                  'alternateName': 'Tutsplus',
                  'url': 'https://tutsplus.com/'
                }
              ]
            })
          }}
        />
        <ScrollToTop />
        <Analytics.Page trackingId={snakeCase(pageId)}>
          <PageLayoutWithHeader>{children}</PageLayoutWithHeader>
        </Analytics.Page>
      </I18nContext.Provider>
    </UIProvider>
  )
}
